import { ModalCustom } from "components/ModalCustom";
import useBackReload from "hooks/useBackReload";
import useCheckConnectInternet from "hooks/useCheckConnectInternet";
import useOrientationChange from "hooks/useOrientationChange";
import useSetVarHeight from "hooks/useSetVarHeight";
import { NotFoundPage } from "pages/not-found";
import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { USER_ROUTES } from "router";
import "./App.css";

export const ConnectInternetContext = createContext();
function App() {
  useOrientationChange();
  useSetVarHeight();
  useBackReload();
  const {
    isOnline,
    isShowErrorInternet,
    setIsShowErrorInternet,
    handleShowWhenNotConnectInternet,
  } = useCheckConnectInternet();

  const renderRoute = (list) => {
    return (
      <>
        {list.map((route) => {
          return <Route key={route.path} {...route} />;
        })}
      </>
    );
  };

  return (
    <ConnectInternetContext.Provider
      value={{
        setIsShowErrorInternet,
        handleShowWhenNotConnectInternet,
      }}
    >
      <div className="App">
        <ModalCustom
          open={isShowErrorInternet && !isOnline}
          wrapperClassName="popup-connect-internet"
          footer={
            <div
              className="btn-close-popup"
              onClick={() => {
                setIsShowErrorInternet(false);
              }}
            >
              OK
            </div>
          }
        >
          <div className="text-connect-internet">
            ネットワークに接続されていません。
          </div>
        </ModalCustom>
        <Routes>
          {renderRoute(USER_ROUTES)}
          <Route path="*" element={<NotFoundPage />} />;
        </Routes>
      </div>
    </ConnectInternetContext.Provider>
  );
}

export default App;
