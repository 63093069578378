import { CaptureFramePage } from "pages/capture-frame";
import { ConfirmNamePage } from "pages/confirm-name";
import { ConfirmResetPage } from "pages/confirm-reset";
import { Goal } from "pages/goal";
import { NotFoundPage } from "pages/not-found";
import { QuizPage } from "pages/quiz";
import { ReadQRCodePage } from "pages/read-qrcode";
import { StartPage } from "pages/start";
import { SuccessAnswerPage } from "pages/success-answer";
import { WrongAnswerPage } from "pages/wrong-answer";

export const USER_ROUTES = [
  {
    path: "/goal",
    element: <Goal />,
  },
  {
    path: "/",
    element: <StartPage />,
  },
  {
    path: "/start",
    element: <StartPage />,
  },
  {
    path: "/read-qrcode/:id",
    element: <ReadQRCodePage />,
  },
  {
    path: "/confirm-reset",
    element: <ConfirmResetPage />,
  },
  {
    path: "/confirm-name",
    element: <ConfirmNamePage />,
  },
  {
    path: "/quiz/:id",
    element: <QuizPage />,
  },
  {
    path: "/wrong-answer",
    element: <WrongAnswerPage />,
  },
  {
    path: "/success-answer/:id",
    element: <SuccessAnswerPage />,
  },
  {
    path: "/capture-frame/:id",
    element: <CaptureFramePage />,
  },
  {
    path: "/not-found",
    element: <NotFoundPage />,
  },
];

export const USER_ROUTES_NOT_UUID = [
  {
    path: "/start",
    element: <StartPage />,
  },
  {
    path: "/not-found",
    element: <NotFoundPage />,
  },
];
