import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ModalCustom } from "components/ModalCustom";
import { ButtonCustom } from "components/button";
import { ImageCustom } from "components/image-custom";
import { getEnv } from "configs/env";
import useInfoQuiz from "hooks/infoQuiz";
import { useEffect, useState } from "react";
import { LOCAL_STORAGE_KEY, nickName } from "utils/constants";
import LocalStorage from "utils/storage";
import { StartStyled } from "./styled";

export const StartPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [messageShow, setMessageShow] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [thumbnailStart, setThumbnailStart] = useState(null);
  const {
    getDataEvent,
    getDataEventAndIdentity,
    startEventCurrentQuiz,
    getIdentityEvent,
  } = useInfoQuiz();

  const handleShowError = (message = "") => {
    setMessageShow(message);
    setIsShowError(true);
  };

  useEffect(() => {
    const fpPromise = FingerprintJS.load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      LocalStorage.set(LOCAL_STORAGE_KEY.UUID_DEVICE, result.visitorId);
      if (nickName) {
        getDataEventAndIdentity(
          result.visitorId,
          nickName,
          setThumbnailStart,
          setIsLoading
        );
      } else {
        getDataEvent(setThumbnailStart, setIsLoading);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickName]);

  const startEvent = async () => {
    if (nickName) {
      await getIdentityEvent(
        null,
        nickName,
        null,
        true,
        async (userId) => {
          await startEventCurrentQuiz(userId);
        },
        handleShowError
      );
    } else {
      return (window.location.href = "/confirm-name");
    }
  };

  return (
    <>
      <ModalCustom
        open={isShowError}
        wrapperClassName="popup-connect-error"
        footer={
          <div
            className="btn-close-popup"
            onClick={() => {
              setIsShowError(false);
            }}
          >
            OK
          </div>
        }
      >
        <div className="text-error-popup">{messageShow}</div>
      </ModalCustom>
      {!isLoading && (
        <StartStyled className="with-bg-img">
          {thumbnailStart && (
            <ImageCustom
              rootClassName="wrapper-img-start"
              className="img-start"
              src={`${getEnv("REACT_APP_IMG_URL")}/${thumbnailStart}`}
              alt="img"
            />
          )}
          <ButtonCustom onTouchEnd={startEvent} rootClassName="button-start">
            スタート
          </ButtonCustom>
        </StartStyled>
      )}
    </>
  );
};
