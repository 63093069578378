import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    .head-reset {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 24px;
      border: 2px solid #afb1b6;
      background: #fff;
      padding: 30px 26px;
      height: calc(var(--doc-height) - 70px - 26px - 18px - 60px - 4px);
      max-height: 577px;
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        height: calc(var(--doc-height) - 160px - 26px - 18px - 60px - 4px);
      }
    }
    .group-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .button-cancel {
        margin-right: 26px;
      }
      .button-cancel,
      .button-ok {
        width: 167px;
        height: 90px;
      }
      ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
        .button-cancel {
          margin-right: 26px;
        }
        .button-cancel,
        .button-ok {
          width: 147px;
          height: 70px;
        }
      }
    }
  }
`;

export const ConfirmResetStyled = styled.div`
  width: 100%;
  height: var(--doc-height, 100vh);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 10px;
  .head-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 24px;
    border: 2px solid #afb1b6;
    background: #fff;
    padding: 30px 26px;
    height: calc(var(--doc-height) - 70px - 26px - 18px - 60px - 4px);
    max-height: 577px;
    margin-bottom: 18px;
    &-title {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 27px;
    }
    &-icon {
      margin-bottom: 36px;
    }
    &-description {
      color: #636363;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
      width: 307px;
    }
    &-sub-title {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    &-sub-description {
      color: #fc0000;
      font-size: 11.5px;
      font-weight: 400;
      width: 293px;
    }
    ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
      &-title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 18px;
      }
      &-icon {
        margin-bottom: 36px;
        img {
          width: 120px;
        }
      }
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      margin-bottom: 16px;
      &-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 18px;
      }
      &-icon {
        margin-bottom: 36px;
        img {
          width: 120px;
        }
      }
      &-description {
        color: #636363;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
        width: 247px;
      }
      &-sub-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      &-sub-description {
        color: #fc0000;
        font-size: 10px;
        font-weight: 400;
        width: 247px;
      }
      &-icon {
        margin-bottom: 12px;
        img {
          width: 80px;
        }
      }
    }
    @media screen and (max-width: 320px) {
      width: 100%;
      padding: 20px 4px;
      &-description {
        color: #636363;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 4px;
        width: 247px;
      }
      &-sub-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 4px;
      }
      &-sub-description {
        color: #fc0000;
        font-size: 10px;
        font-weight: 400;
        width: 233px;
      }
      &-icon {
        margin-bottom: 16px;
        img {
          width: 80px;
        }
      }
    }
  }
  .group-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      .button-cancel {
        margin-right: 26px;
      }
      .button-cancel,
      .button-ok {
        width: 147px;
        height: 70px;
      }
    }
    @media screen and (max-width: 357px) {
      width: 100%;
    }
    .button-cancel {
      user-select: none;
      margin-right: 8px;
    }
    .button-ok {
      user-select: none;
    }
  }
  .wrapper-img-confirm {
    .img-confirm {
      border-radius: 24px;
    }
  }
  ${ScreenReponsive}
`;
