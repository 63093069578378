import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.lessThan(SIZE.XXS)} {
    .wrapper {
      padding: 20px 16px;
      width: 100%;
    }
    .wrap-info {
      width: 100%;
      .input-name {
        width: calc(100% - 24px);
      }
    }
  }
  ${ResponsiveUI.lessThan(SIZE.XXXS)} {
    .wrap-info {
      width: 100%;
      .input-name {
        width: calc(100% - 24px);
        height: 50px;
      }
      .item {
        font-size: 14px;
        .item-child {
          font-size: 14px;
        }
      }
    }
    .button-start-name {
      margin-top: 20px;
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC12)} {
      .wrap-info {
        padding: 16px 25px;
        .item {
          font-size: 12px;
          .item-child {
            font-size: 12px;
          }
        }
      }
      .button-start-name {
        margin-top: 12px;
      }
    }
  }
`;

export const ConfirmNameStyled = styled.div`
  width: 100%;
  height: var(--doc-height, 100vh);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
  .wrap-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background: #fff2e5;
    box-sizing: border-box;
    padding: 40px 25px;
    width: 358px;
    height: calc(var(--doc-height) - 70px - 40px - 80px);
    max-height: 567px;
    .header {
      color: #118007;
      font-size: 26px;
      font-weight: 900;
      text-align: center;
    }
    .input-name {
      margin-top: 24px;
      width: 282px;
      height: 56px;
      border-radius: 14px;
      border: 1px solid #bebebe;
      background: #fff;
      padding: 0px 12px;
      color: #00454c;
      font-weight: 400;
      font-size: 16px;
      -webkit-appearance: none;
      outline: none !important;
      &:focus,
      :hover,
      :target,
      :visited,
      :active,
      :focus-visible {
        border: 1px solid #bebebe !important;
        outline: none !important;
      }
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      color: #00454c;
      font-size: 18px;
      font-weight: 500;
      align-self: flex-start;
      &.mgt {
        margin-top: 24px;
      }
      .item-child {
        font-size: 18px;
        font-weight: 500;
      }
      .item-empty {
        display: block;
        width: 18px;
      }
    }
  }
  .button-start-name {
    margin-top: 40px;
    height: 70px !important;
    min-height: 70px;
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC17)} {
    padding: 40px 16px;
    .wrap-info {
      height: calc(var(--doc-height) - 70px - 30px - 40px);
      padding: 30px 25px;
      .input-name {
        margin-top: 12px;
      }
      .item {
        font-size: 16px;
        .item-child {
          font-size: 16px;
        }
        &.mgt {
          margin-top: 12px;
        }
      }
    }
    .button-start-name {
      margin-top: 30px;
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
    padding: 20px 16px;
    .wrap-info {
      height: calc(var(--doc-height) - 70px - 48px - 20px);
      padding: 28px 25px;
      .input-name {
        margin-top: 12px;
      }
      .item {
        font-size: 16px;
        .item-child {
          font-size: 16px;
        }
        &.mgt {
          margin-top: 12px;
        }
      }
    }
    .button-start-name {
      margin-top: 30px;
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC13)} {
    padding: 20px 16px;
    .wrap-info {
      height: calc(var(--doc-height) - 70px - 40px - 18px);
      padding: 20px 25px;
      .input-name {
        margin-top: 8px;
      }
      .item {
        font-size: 16px;
        .item-child {
          font-size: 16px;
        }
        &.mgt {
          margin-top: 8px;
        }
      }
    }
    .button-start-name {
      margin-top: 16px;
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC12)} {
    .wrap-info {
      .item {
        font-size: 14px;
        .item-child {
          font-size: 14px;
        }
      }
    }
    .button-start-name {
      margin-top: 16px;
    }
  }
  ${ScreenReponsive}
`;
