import styled from "styled-components";

export const ModalCustomStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.45);
  .popup-wrapper {
    position: absolute;
    top: 10px;
    left: 50%;
    padding: 22px 26px;
    border-radius: 20px;
    background: #fff;
    transform: translateX(-50%);
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.popup-connect-internet {
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      .text-connect-internet {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        padding: 12px 12px;
        min-width: 220px;
        white-space: nowrap;
        color: #1e1e1e;
      }
      .popup-footer {
        width: 100%;
        margin-top: 20px;
        padding-right: 46px;
        .btn-close-popup {
          color: #1971e0;
          font-size: 16px;
          font-weight: 500;
          text-align: end;
          width: 100%;
        }
      }
    }
    &.popup-connect-error {
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding: 16px 15px;
      .text-error-popup {
        font-size: 18px;
        font-weight: 400;
        text-align: start;
        padding: 0;
        width: 320px;
        white-space: pre-wrap;
        color: #1e1e1e;
      }
      .popup-footer {
        width: 100%;
        margin-top: 20px;
        padding-right: 46px;
        .btn-close-popup {
          color: #1971e0;
          font-size: 16px;
          font-weight: 500;
          text-align: end;
          width: 100%;
        }
      }
    }
  }
`;
