import { useEffect } from "react";

const useSetVarHeight = () => {
  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
      doc.style.setProperty("--doc-width", `${window.innerWidth}px`);
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
  }, []);
};

export default useSetVarHeight;
