import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.MD)} {
    .popup-wrapper {
      .popup-content {
        margin-top: 16px;
        text-align: center;
        position: relative;
        .wrap-hint-img {
          height: calc(var(--doc-height) * 80 / 100);
          width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
          /* width: 306px;
          height: 464px; */
          max-height: unset;
          .hint-img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
      .group-button-footer {
        margin-top: 22px;
        button {
          width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
        }
      }
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      .popup-wrapper {
        width: fit-content;
        margin: auto;
        .popup-content {
          margin-top: 16px;
          text-align: center;
          position: relative;
          .wrap-hint-img {
            height: calc(var(--doc-height) * 63 / 100);
            width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
            .hint-img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
        }
        .group-button-footer {
          margin-top: 22px;
          button {
            width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
          }
        }
      }
    }
  }
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .popup-wrapper {
      .popup-content {
        margin-top: 16px;
        text-align: center;
        position: relative;
        .wrap-hint-img {
          height: calc(var(--doc-height) * 73 / 100);
          width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
          /* width: 306px;
          height: 464px; */
          max-height: unset;
          .hint-img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
      .group-button-footer {
        margin-top: 22px;
        button {
          width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
        }
      }
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      .popup-wrapper {
        width: fit-content;
        margin: auto;
        .popup-content {
          margin-top: 16px;
          text-align: center;
          position: relative;
          .wrap-hint-img {
            height: calc(var(--doc-height) * 63 / 100);
            width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
            .hint-img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
        }
        .group-button-footer {
          margin-top: 22px;
          button {
            width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
          }
        }
      }
    }
  }
`;

export const HintStyled = styled.div`
  .popup-wrapper {
    width: fit-content;
    margin: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    .popup-content {
      margin-top: 16px;
      text-align: center;
      position: relative;
      .wrap-hint-img {
        max-width: 100%;
        width: 316px;
        height: calc(var(--doc-height) * 70 / 100);
        max-height: 464px;
        @media screen and (max-width: 360px) {
          width: 280px !important ;
        }
        @media screen and (max-width: 320px) {
          width: 260px !important ;
          max-height: 314px;
        }
        @media screen and (max-width: 280px) {
          width: 200px;
          max-height: calc(200px * 16 / 9);
        }
        .hint-img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .icon-hint-modal {
        position: absolute;
        user-select: none;
        z-index: 2;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 7px;
        background: linear-gradient(180deg, #f1f4f9 22.73%, #c6d1e3 105.84%);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
        width: 70px;
        color: #f09813;
        font-size: 16px;
        font-weight: 900;
        padding: 10px 12px 9px 13px;
      }
    }
  }

  .group-button-footer {
    width: 100%;
    text-align: center;
    margin-top: 22px;
    button {
      width: 316px;
      @media screen and (max-width: 360px) {
        width: 280px;
      }
      @media screen and (max-width: 320px) {
        width: 260px;
      }
      @media screen and (max-width: 280px) {
        width: 200px;
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
    .popup-wrapper {
      width: fit-content;
      margin: auto;
      padding: 22px 18px;
    }
  }

  ${ScreenReponsive}
`;

export const HintButtonStyled = styled.div`
  cursor: pointer;
  .text {
    color: #e69112;
    font-size: 18px;
    font-weight: 900;
  }
`;
