import ResetIcon from "assets/images/reset_icon.svg";
import { ButtonCustom } from "components/button";
import useInfoQuiz from "hooks/infoQuiz";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "utils/constants";
import LocalStorage from "utils/storage";
import { ConfirmResetStyled } from "./styled";

export const ConfirmResetPage = () => {
  const navigate = useNavigate();
  const { resetEvent } = useInfoQuiz();

  useEffect(() => {
    if (!LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      return (window.location.href = `/start`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)]);

  const handleNo = () => {
    if (LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      navigate(-1);
    } else {
      return (window.location.href = `/start`);
    }
  };
  return (
    <ConfirmResetStyled className="with-bg-img">
      <div className="head-reset">
        <div className="head-reset-title">【リセット】</div>
        <div className="head-reset-icon">
          <img alt="reset-icon" src={ResetIcon} />
        </div>
        <div className="head-reset-description">
          リセットを行うと、回答済みのクイズの ステータスを消去し、クイズ開始と同じ状態にします。
        </div>
        <div className="head-reset-sub-title">本当にリセットを行いますか？</div>
        <div className="head-reset-sub-description">
          ※途中退出や2周目を回りたいといった時に本リセットボタンをご利用ください。
        </div>
      </div>
      <div className="group-button">
        <ButtonCustom
          onTouchEnd={handleNo}
          width={175}
          typeButton="cancel"
          rootClassName="button-cancel"
        >
          いいえ
        </ButtonCustom>
        <ButtonCustom
          width={175}
          type="primary"
          onTouchEnd={() => {
            if (LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
              resetEvent();
            } else {
              return (window.location.href = `/start`);
            }
          }}
          rootClassName="button-ok"
        >
          はい
        </ButtonCustom>
      </div>
    </ConfirmResetStyled>
  );
};
