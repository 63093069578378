import { ConnectInternetContext } from "App";
import { notification } from "antd";
import EVENT_API from "api/event";
import { useContext } from "react";
import { useDeviceData } from "react-device-detect";
import {
  ERROR_VALID_NICK_NAME,
  LIST_ERROR,
  LOCAL_STORAGE_KEY,
} from "utils/constants";
import LocalStorage from "utils/storage";
import { clearDataLocalStore, getCurrentQuizByOrder } from "utils/uttil";

const useInfoQuiz = () => {
  const { browser, os } = useDeviceData();
  const { handleShowWhenNotConnectInternet } = useContext(
    ConnectInternetContext
  );
  const uuidLocal = LocalStorage.get(LOCAL_STORAGE_KEY.UUID_DEVICE);
  const nickname = LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME);
  const listQuizLocal = LocalStorage.get(LOCAL_STORAGE_KEY.LIST_QUIZ);
  const currentQuizLocal = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENT_QUIZ);

  const photoFrame = LocalStorage.get(LOCAL_STORAGE_KEY.PHOTO_FRAME);
  const userId = LocalStorage.get(LOCAL_STORAGE_KEY.USER_ID);
  const thumbnailStart = LocalStorage.get(LOCAL_STORAGE_KEY.THUMBNAIL_START);
  const correctUrl = LocalStorage.get(LOCAL_STORAGE_KEY.CORRECT_URL);
  const wrongUrl = LocalStorage.get(LOCAL_STORAGE_KEY.WRONG_URL);
  const thumbnailGoal = LocalStorage.get(LOCAL_STORAGE_KEY.THUMBNAIL_GOAL);
  const countQuiz = LocalStorage.get(LOCAL_STORAGE_KEY.COUNT_QUIZ);
  const currentQuiz = currentQuizLocal ? JSON.parse(currentQuizLocal) : {};
  const listQuiz = listQuizLocal ? JSON.parse(listQuizLocal) : {};

  const getDataEventAndIdentity = async (
    uuidPass,
    name,
    setThumbnailStart,
    setIsLoading
  ) => {
    try {
      const uuid = uuidPass || uuidLocal;
      const infoBrowser = `${JSON.stringify(browser)}${JSON.stringify(os)}`;
      const [dataEvent, dataQuiz] = await Promise.all([
        EVENT_API.getEvent(),
        EVENT_API.getHistoryEvent({
          uuid,
          infoBrowser,
          nickname: name || nickname,
        }),
      ]);
      setThumbnailStart && setThumbnailStart(dataEvent?.data?.start_url);
      LocalStorage.set(
        LOCAL_STORAGE_KEY.THUMBNAIL_START,
        dataEvent?.data?.start_url
      );
      LocalStorage.set(
        LOCAL_STORAGE_KEY.CORRECT_URL,
        dataEvent?.data?.correct_url
      );
      LocalStorage.set(LOCAL_STORAGE_KEY.WRONG_URL, dataEvent?.data?.wrong_url);
      LocalStorage.set(
        LOCAL_STORAGE_KEY.THUMBNAIL_GOAL,
        dataEvent?.data?.goal_url
      );
      const userId = dataQuiz?.data?.user_id;
      LocalStorage.set(LOCAL_STORAGE_KEY.USER_ID, userId);

      const listQuiz = dataQuiz?.data?.event_history?.quiz;
      if (listQuiz && listQuiz?.length) {
        LocalStorage.set(LOCAL_STORAGE_KEY.LIST_QUIZ, JSON.stringify(listQuiz));
        LocalStorage.set(LOCAL_STORAGE_KEY.COUNT_QUIZ, listQuiz?.length);

        setIsLoading && setIsLoading(false);
        const currentQuizFind = listQuiz?.find((e) => e?.current_flg === 1);
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CURRENT_QUIZ,
          JSON.stringify(currentQuizFind)
        );
        if (currentQuizFind?.url) {
          return (window.location.href = `/success-answer/${currentQuizFind?.quiz_id}`);
        }
        return (window.location.href = `/quiz/${currentQuizFind?.quiz_id}`);
      }
    } catch (error) {
      if (handleShowWhenNotConnectInternet) {
        handleShowWhenNotConnectInternet();
      }
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

  const getIdentityEvent = async (
    uid,
    name,
    idQuizRedirect,
    isRedirectToQuiz = false,
    cb = () => {},
    handleShowError = () => {}
  ) => {
    try {
      const nName = name || nickname;
      const infoBrowser = `${JSON.stringify(browser)}${JSON.stringify(os)}`;
      const uuid = uid || LocalStorage.get(LOCAL_STORAGE_KEY.UUID_DEVICE);
      const dataQuiz = await EVENT_API.getHistoryEvent({
        uuid,
        infoBrowser,
        nickname: nName,
      });
      if (dataQuiz?.success) {
        const userId = dataQuiz?.data?.user_id;
        LocalStorage.set(LOCAL_STORAGE_KEY.USER_ID, userId);
        LocalStorage.set(LOCAL_STORAGE_KEY.NICK_NAME, nName);

        const listQuiz = dataQuiz?.data?.event_history?.quiz;
        if (listQuiz && listQuiz?.length) {
          LocalStorage.set(
            LOCAL_STORAGE_KEY.LIST_QUIZ,
            JSON.stringify(listQuiz)
          );
          LocalStorage.set(LOCAL_STORAGE_KEY.COUNT_QUIZ, listQuiz?.length);
          const currentQuizFind = listQuiz?.find((e) => e?.current_flg === 1);
          LocalStorage.set(
            LOCAL_STORAGE_KEY.CURRENT_QUIZ,
            JSON.stringify(currentQuizFind)
          );
          if (currentQuizFind?.url) {
            if (
              isRedirectToQuiz ||
              (idQuizRedirect && +idQuizRedirect !== +currentQuizFind?.quiz_id)
            ) {
              return (window.location.href = `/success-answer/${currentQuizFind?.quiz_id}`);
            }
            cb && cb(userId);
            return;
          }
          if (
            isRedirectToQuiz ||
            (idQuizRedirect && +idQuizRedirect !== +currentQuizFind?.quiz_id)
          ) {
            return (window.location.href = `/quiz/${currentQuizFind?.quiz_id}`);
          }
        }
        cb && cb(userId);
      } else {
        if (LIST_ERROR?.includes(dataQuiz?.message)) {
          handleShowError(ERROR_VALID_NICK_NAME);
        } else {
          notification.error({
            message: dataQuiz?.message,
          });
        }
      }
    } catch (error) {
      if (handleShowWhenNotConnectInternet) {
        handleShowWhenNotConnectInternet();
      }
    }
  };

  const getDataEvent = async (setThumbnailStart, setIsLoading) => {
    try {
      const dataEvent = await EVENT_API.getEvent();
      if (dataEvent?.success) {
        setThumbnailStart(dataEvent?.data?.start_url);
        LocalStorage.set(
          LOCAL_STORAGE_KEY.THUMBNAIL_START,
          dataEvent?.data?.start_url
        );
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CORRECT_URL,
          dataEvent?.data?.correct_url
        );
        LocalStorage.set(
          LOCAL_STORAGE_KEY.WRONG_URL,
          dataEvent?.data?.wrong_url
        );
        LocalStorage.set(
          LOCAL_STORAGE_KEY.THUMBNAIL_GOAL,
          dataEvent?.data?.goal_url
        );
      } else {
        notification.error({
          message: dataEvent?.message,
        });
      }
    } catch (error) {
      if (handleShowWhenNotConnectInternet) {
        handleShowWhenNotConnectInternet();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const startEventCurrentQuiz = async (id) => {
    try {
      const dataQuiz = await EVENT_API.startEvent({
        user_id: id || userId,
      });
      if (dataQuiz?.success) {
        const listQuiz = dataQuiz?.data?.event_history?.quiz;
        if (listQuiz && listQuiz?.length) {
          LocalStorage.set(
            LOCAL_STORAGE_KEY.LIST_QUIZ,
            JSON.stringify(listQuiz)
          );
          LocalStorage.set(LOCAL_STORAGE_KEY.COUNT_QUIZ, listQuiz?.length);
          const currentQuizFind = listQuiz?.find((e) => e?.current_flg === 1);
          LocalStorage.set(
            LOCAL_STORAGE_KEY.CURRENT_QUIZ,
            JSON.stringify(currentQuizFind)
          );
          return (window.location.href = `/quiz/${currentQuizFind?.quiz_id}`);
        }
      } else {
        notification.error({
          message: dataQuiz?.message,
        });
      }
    } catch (error) {
      if (handleShowWhenNotConnectInternet) {
        handleShowWhenNotConnectInternet();
      }
    }
  };

  const resetEvent = async () => {
    try {
      const userId = LocalStorage.get(LOCAL_STORAGE_KEY.USER_ID);
      const rs = await EVENT_API.resetEventApi({
        user_id: userId,
      });
      if (rs?.success) {
        clearDataLocalStore().then(() => {
          window.location.href = "/start";
        });
      } else {
        notification.error({
          message: rs?.message,
        });
      }
    } catch (error) {
      if (handleShowWhenNotConnectInternet) {
        handleShowWhenNotConnectInternet();
      }
    }
  };

  const setNextCurrentQuiz = (nextCurrent, isRedirect, isGoal) => {
    if (isRedirect) {
      if (isGoal) {
        return (window.location.href = `/goal`);
      } else {
        const nextQuiz = getCurrentQuizByOrder(listQuiz, nextCurrent);
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CURRENT_QUIZ,
          JSON.stringify(nextQuiz)
        );
        if (nextQuiz?.quiz_id) {
          return (window.location.href = `/quiz/${nextQuiz?.quiz_id}`);
        } else {
          return (window.location.href = `/goal`);
        }
      }
    }
  };

  const checkCurrentQuiz = (id) => {
    if (currentQuiz?.quiz_id) {
      if (+currentQuiz?.quiz_id !== +id) {
        return (window.location.href = `/quiz/${currentQuiz?.quiz_id}`);
      }
    } else {
      return (window.location.href = `/start`);
    }
  };

  return {
    photoFrame,
    listQuiz,
    currentQuiz,
    countQuiz,
    thumbnailStart,
    correctUrl,
    wrongUrl,
    thumbnailGoal,
    userId,
    setNextCurrentQuiz,
    getDataEventAndIdentity,
    checkCurrentQuiz,
    startEventCurrentQuiz,
    resetEvent,
    getIdentityEvent,
    getDataEvent,
  };
};

export default useInfoQuiz;
