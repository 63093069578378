import LocalStorage from "./storage";

export const LOCAL_STORAGE_KEY = {
  USER_ACCESS_TOKEN: "USER_ACCESS_TOKEN",
  LANGUAGE: "LANGUAGE",
  UUID_DEVICE: "UUID_DEVICE",
  CURRENT_QUIZ: "CURRENT_QUIZ",
  COUNT_QUIZ: "COUNT_QUIZ",
  LIST_QUIZ: "LIST_QUIZ",
  THUMBNAIL_START: "THUMBNAIL_START",
  THUMBNAIL_GOAL: "THUMBNAIL_GOAL",
  USER_ID: "USER_ID",
  PHOTO_FRAME: "PHOTO_FRAME",
  CORRECT_URL: "CORRECT_URL",
  WRONG_URL: "WRONG_URL",
  NICK_NAME: "NICK_NAME",
};

export const SYSTEM_ERROR = {
  NETWORK_ERROR: {
    STATUS: "Network Error",
    MESSAGE: "Request has been cancelled",
  },

  TIMEOUT_ERROR: {
    STATUS: "Request Timeout",
    MESSAGE: "The Request Has Timed Out",
  },
};

export const FACING_MODE_USER = "user";
export const FACING_MODE_ENVIRONMENT = "environment";

export const LIST_ERROR = [
  "NICKNAME_IS_REQUIRED",
  "NICKNAME_IS_EXISTS",
  "NICKNAME_IS_INVALID",
];

export const ERROR_VALID_NICK_NAME =
  "入力されたニックネームの参加者がすでに存在します。 別のニックネームを入力してください。";

export const isHaveUUID = !!LocalStorage.get(LOCAL_STORAGE_KEY.UUID_DEVICE);

export const nickName = LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME);
