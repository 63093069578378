import { ButtonCustom } from "components/button";
import { ImageCustom } from "components/image-custom";
import { getEnv } from "configs/env";
import useInfoQuiz from "hooks/infoQuiz";
import { useEffect } from "react";
import { LOCAL_STORAGE_KEY } from "utils/constants";
import LocalStorage from "utils/storage";
import { WrongAnswerStyled } from "./styled";

export const WrongAnswerPage = () => {
  const { wrongUrl, currentQuiz } = useInfoQuiz();

  useEffect(() => {
    if (!LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      return (window.location.href = `/start`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)]);

  const backToQuiz = () => {
    if (LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      return (window.location.href = `/quiz/${currentQuiz?.quiz_id}`);
    } else {
      return (window.location.href = `/start`);
    }
  };
  return (
    <WrongAnswerStyled className="with-bg-img">
      <div></div>
      <ImageCustom
        alt="img-wrong"
        src={`${getEnv("REACT_APP_IMG_URL")}/${wrongUrl}`}
        width={200}
        height={200}
      />
      <ButtonCustom onTouchEnd={backToQuiz} typeButton="cancel">
        戻る
      </ButtonCustom>
    </WrongAnswerStyled>
  );
};
