import { ImageCustom } from "components/image-custom";
import { ButtonStyled } from "./styled";
import BlurIcon from "assets/images/blur-button.svg";

export const ButtonCustom = ({
  children,
  type = "ghost",
  typeButton = "submit",
  bordered = false,
  className,
  width = 358,
  height = 70,
  ...rest
}) => {
  return (
    <ButtonStyled
      width={width}
      height={height}
      className={`${
        bordered ? `bordered bordered-${typeButton}` : typeButton
      } ${className}`}
      {...rest}
    >
      {children}
      {!bordered && (
        <ImageCustom rootClassName="blur-icon" alt="blur-icon" src={BlurIcon} />
      )}
    </ButtonStyled>
  );
};
