import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .group-head {
      height: 80px;
      max-width: calc(
        calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
          16
      );
      .count-quiz {
        font-size: 24px;
        font-weight: 700;
      }
      &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        width: calc(
          calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
            16
        );
        .count-quiz {
          font-size: 18px;
          font-weight: 700;
        }
        &-right {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
    .quiz-order {
      font-size: 32px;
      font-weight: 700;
      margin-top: 6px;
      margin-bottom: 26px;
      height: 30px;
    }
    .group-button {
      margin-top: 28px;
      .button-start {
        width: calc(
          calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
            16
        );
        max-width: 100%;
        height: 80px;
        font-size: 24px;
      }
    }
    .group-img-quiz {
      position: relative;
      max-width: 100%;
      width: calc(
        calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
          16
      );
      height: calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px);

      max-height: unset;
      .wrapper-img-start {
        height: 100%;
        width: 100%;
      }
      .img-quiz {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 24px;
      }
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        width: calc(
          calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
            16
        );
        height: calc(
          var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px
        );
      }
    }
  }
  ${ResponsiveUI.greaterThan(SIZE.MD)} {
    .group-head {
      height: 80px;
      max-width: calc(
        calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
          16
      );
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        &-right {
          display: flex;
          flex-direction: row;
          align-items: center;
          .btn-reset,
          .btn-hint {
            width: 80px;
            font-size: 12px;
            padding: 8px 2px;
          }
        }
      }
      ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
        &-right {
          display: flex;
          flex-direction: row;
          align-items: center;
          .btn-reset,
          .btn-hint {
            width: 50px;
            font-size: 12px;
            padding: 8px 2px;
          }
        }
      }
    }

    .group-img-quiz {
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        width: calc(
          calc(var(--doc-height) - 80px - 80px - 10px - 30px - 36px - 32px) * 9 /
            16
        );
        height: calc(
          var(--doc-height) - 80px - 80px - 10px - 30px - 36px - 32px
        );
      }
    }

    .group-button {
      margin-top: 28px;
      .button-start {
        width: calc(
          calc(var(--doc-height) - 80px - 80px - 28px - 30px - 36px - 32px) * 9 /
            16
        );
        max-width: 100%;
        height: 80px;
        font-size: 24px;
      }
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        margin-top: 14px;
        .button-start {
          width: calc(
            calc(var(--doc-height) - 80px - 80px - 10px - 30px - 36px - 32px) *
              9 / 16
          );
          max-width: 100%;
          height: 60px;
          font-size: 24px;
        }
      }
    }
  }
`;

export const QuizStyled = styled.div`
  width: 100%;
  height: var(--doc-height, 100%);
  min-height: var(--doc-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;

  .ant-modal {
    top: 40px;
  }

  .group-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 358px;
    height: 60px;
    .count-quiz {
      font-size: 18px;
      font-weight: 700;
    }
    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-reset {
        white-space: nowrap;
        user-select: none;
        margin-right: 8px;
        border-radius: 7px;
        background: linear-gradient(180deg, #f1f4f9 22.73%, #c6d1e3 105.84%);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
        color: #d91515;
        font-size: 16px;
        font-weight: 900;
        padding: 10px 12px 9px 13px;
        width: 70px;
        text-align: center;
      }
      .btn-hint {
        border-radius: 7px;
        user-select: none;
        background: linear-gradient(180deg, #f1f4f9 22.73%, #c6d1e3 105.84%);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
        white-space: nowrap;
        color: #f09813;
        font-size: 16px;
        font-weight: 900;
        padding: 10px 12px 9px 13px;
        width: 70px;
        text-align: center;
      }
    }
    @media screen and (max-width: 360px) {
      width: 330px;
    }
    @media screen and (max-width: 320px) {
      width: 100%;
      height: 40px;
      &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn-reset,
        .btn-hint {
          width: 50px;
          font-size: 14px;
        }
      }
    }
  }

  .quiz-order {
    font-size: 20px;
    font-weight: 700;
    margin-top: 2px;
    margin-bottom: 6px;
    height: 30px;
  }

  .group-button {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .button-start {
      @media screen and (max-width: 360px) {
        width: 330px;
      }
      @media screen and (max-width: 320px) {
        width: 330px;
        height: 50px;
      }
    }
    @media screen and (max-width: 320px) {
      width: 100%;
    }
  }

  .group-img-quiz {
    position: relative;
    max-width: 100%;
    width: 358px;
    aspect-ratio: 9 / 16;
    height: calc(var(--doc-height) - 60px - 30px - 70px - 24px - 32px);
    max-height: 543px;
    .wrapper-img-start {
      height: 100%;
      width: 100%;
    }
    .img-quiz {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
    .number-quiz-id {
      position: absolute;
      top: 10%;
      right: 10%;
      font-size: 28px;
      font-weight: 700;
      color: #000;
    }
  }
  ${ScreenReponsive}
`;
