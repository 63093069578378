import { useCallback, useEffect, useState } from "react";
import { isIPad13, isMobile, isTablet } from "react-device-detect";

const useOrientationChange = (isShow = true) => {
  const [orientation, setOrientation] = useState(
    window.innerHeight > window.innerWidth ? 0 : 90
  );
  const widthScreen = window.innerWidth;
  const heightScreen = window.innerHeight;

  const checkScreenToShow = useCallback(() => {
    if (isTablet || isIPad13) {
      return false;
    }
    if (isMobile) {
      return true;
    }
    if (widthScreen < 576) {
      return heightScreen < 1024;
    } else if (widthScreen < 768) {
      return true;
    }
    return false;
  }, [heightScreen, widthScreen]);

  useEffect(() => {
    function handleOrientation(e) {
      setOrientation(e?.target?.orientation);
      if (
        checkScreenToShow() &&
        (e?.target?.orientation === 90 || e?.target?.orientation === -90) &&
        isShow
      ) {
        alert("画面を縦向きにしてください");
      }
    }

    window.addEventListener("orientationchange", handleOrientation);
    return () =>
      window.removeEventListener("orientationchange", handleOrientation);
  }, [checkScreenToShow, isShow]);

  return {
    orientation,
  };
};

export default useOrientationChange;
