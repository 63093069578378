import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .qr-reader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const ReadQRCodeStyled = styled.div`
  width: 100%;
  height: 100dvh;
  height: var(--doc-height);
  .wrap-reader {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    .qr-reader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      video {
        width: 412px !important;
        height: 412px !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        position: absolute;
        @media screen and (max-width: 414px) {
          max-width: 390px !important;
          max-height: 390px !important;
          min-width: 390px !important;
          min-height: 390px !important;
        }
        @media screen and (max-width: 375px) {
          max-width: 375px !important;
          max-height: 375px !important;
          min-width: 375px !important;
          min-height: 375px !important ;
        }
        ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
          max-width: 375px !important;
          max-height: 375px !important;
          min-width: 375px !important;
          min-height: 375px !important ;
        }
        @media screen and (max-width: 360px) {
          max-width: 360px !important ;
          max-height: 360px !important;
          min-width: 360px !important;
          min-height: 360px !important;
        }
        @media screen and (max-width: 320px) {
          max-width: 320px !important;
          max-height: 320px !important;
          min-width: 320px !important;
          min-height: 320px !important;
        }
        @media screen and (max-width: 280px) {
          max-width: 280px !important;
          max-height: 280px !important;
          min-width: 280px !important;
          min-height: 280px !important;
        }
        @media screen and (max-height: 480px) {
          max-width: 240px !important;
          max-height: 240px !important;
          min-width: 240px !important;
          min-height: 240px !important;
        }
      }
    }
    .view-finder-qr {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      box-sizing: border-box;
      border: 50px solid rgba(0, 0, 0, 0.3);
      position: absolute;
      width: 412px;
      height: 412px;
      @media screen and (max-width: 414px) {
        max-width: 390px !important;
        max-height: 390px !important;
        min-width: 390px !important;
        min-height: 390px !important;
      }
      @media screen and (max-width: 375px) {
        max-width: 375px !important;
        max-height: 375px !important;
        min-width: 375px !important;
        min-height: 375px !important ;
      }
      ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
        max-width: 375px !important;
        max-height: 375px !important;
        min-width: 375px !important;
        min-height: 375px !important ;
      }
      @media screen and (max-width: 360px) {
        max-width: 360px !important ;
        max-height: 360px !important;
        min-width: 360px !important;
        min-height: 360px !important;
      }
      @media screen and (max-width: 320px) {
        max-width: 320px !important;
        max-height: 320px !important;
        min-width: 320px !important;
        min-height: 320px !important;
      }
      @media screen and (max-width: 280px) {
        max-width: 280px !important;
        max-height: 280px !important;
        min-width: 280px !important;
        min-height: 280px !important;
      }
      @media screen and (max-height: 480px) {
        max-width: 240px !important;
        max-height: 240px !important;
        min-width: 240px !important;
        min-height: 240px !important;
      }

      &-top-left {
        position: absolute;
        top: 0;
        left: 0;
      }
      &-top-right {
        position: absolute;
        top: 0;
        right: 0;
      }
      &-bot-right {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      &-bot-left {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .title {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    width: 300px;
    text-align: center;
    ${ResponsiveUI.lessThanH(SIZEH.MAC16)} {
      font-size: 16px;
      top: 10%;
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      font-size: 16px;
      top: 5%;
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC13)} {
      font-size: 12px;
      top: 4%;
    }
    @media screen and (max-width: 320px) {
      top: 4%;
      width: auto;
      font-size: 16px;
      text-align: center;
    }
    @media screen and (max-width: 280px) {
      font-size: 16px;
      width: auto;
      font-size: 6px;
      text-align: center;
    }
  }

  ${ScreenReponsive}
`;
