import { LOCAL_STORAGE_KEY } from "utils/constants";
import LocalStorage from "utils/storage";

export const getCurrentQuizByOrder = (listQuiz, order) => {
  return listQuiz?.find((e) => +e?.order === +order);
};

export const clearDataLocalStore = () => {
  return new Promise((resole, reject) => {
    LocalStorage.remove(LOCAL_STORAGE_KEY.UUID_DEVICE);
    LocalStorage.remove(LOCAL_STORAGE_KEY.CURRENT_QUIZ);
    LocalStorage.remove(LOCAL_STORAGE_KEY.COUNT_QUIZ);
    LocalStorage.remove(LOCAL_STORAGE_KEY.PHOTO_FRAME);
    LocalStorage.remove(LOCAL_STORAGE_KEY.LIST_QUIZ);
    LocalStorage.remove(LOCAL_STORAGE_KEY.USER_ID);
    resole();
  });
};
