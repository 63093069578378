import { ButtonCustom } from "components/button";
import { ImageCustom } from "components/image-custom";
import { useState } from "react";
import { HintButtonStyled, HintStyled } from "./styled";
import useDefaultPopup from "hooks/useDefaultPopup";
import { ModalCustom } from "components/ModalCustom";

export const Hint = ({ imgHint, contentHint, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useDefaultPopup(isModalOpen);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <HintStyled id="hint-component">
      <HintButtonStyled onTouchEnd={openModal}>
        <div className="btn-hint">ヒント</div>
      </HintButtonStyled>
      <ModalCustom
        open={isModalOpen}
        closable={false}
        onCancel={handleCancel}
        footer={
          <div className="group-button-footer">
            <ButtonCustom
              key="back"
              typeButton="cancel"
              className="btn-back-hint"
              width={306}
              height={64}
              onTouchEnd={handleCancel}
            >
              閉じる
            </ButtonCustom>
          </div>
        }
        getContainer={document.getElementById("hint-component")}
        {...rest}
      >
        <div className="icon-hint-modal">ヒント</div>
        <ImageCustom
          rootClassName="wrap-hint-img"
          className="hint-img"
          alt={imgHint}
          src={imgHint}
        />
        {contentHint && <div>{contentHint}</div>}
      </ModalCustom>
    </HintStyled>
  );
};
