import { ModalCustomStyled } from "./styled";

export const ModalCustom = ({
  open = false,
  onCancel,
  footer,
  children,
  rootClassName = "",
  wrapperClassName = "",
  contentClassName = "",
}) => {
  return (
    <>
      {open && (
        <ModalCustomStyled className={`popup ${rootClassName}`}>
          <div className={`popup-wrapper ${wrapperClassName}`}>
            <div className={`popup-content ${contentClassName}`}>
              {children}
            </div>
            {footer && <div className="popup-footer">{footer}</div>}
          </div>
        </ModalCustomStyled>
      )}
    </>
  );
};
