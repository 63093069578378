import { ConnectInternetContext } from "App";
import { notification } from "antd";
import { QUIZ_API } from "api/quiz";
import { ButtonCustom } from "components/button";
import { Hint } from "components/hint";
import { ImageCustom } from "components/image-custom";
import { getEnv } from "configs/env";
import useInfoQuiz from "hooks/infoQuiz";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY, isHaveUUID, nickName } from "utils/constants";
import LocalStorage from "utils/storage";
import { QuizStyled } from "./styled";

export const QuizPage = () => {
  const { id } = useParams();
  const { currentQuiz, userId, countQuiz, getIdentityEvent } = useInfoQuiz();
  const { handleShowWhenNotConnectInternet } = useContext(
    ConnectInternetContext
  );
  const [dataQuiz, setDataQuiz] = useState(null);

  const getQuiz = async (idQuiz) => {
    if (idQuiz) {
      try {
        const dataQuiz = await QUIZ_API.getQuizById(idQuiz, userId);
        if (dataQuiz?.success) {
          LocalStorage.set(
            LOCAL_STORAGE_KEY.PHOTO_FRAME,
            dataQuiz?.data?.photo_frame
          );
          setDataQuiz(dataQuiz?.data);
        } else {
          notification.error({
            message: dataQuiz?.message,
          });
        }
      } catch (error) {
        if (handleShowWhenNotConnectInternet) {
          handleShowWhenNotConnectInternet();
        }
      }
    }
  };

  const checkQuiz = async () => {
    await getIdentityEvent(null, nickName, id, false, async () => {
      await getQuiz(id);
    });
  };
  useEffect(() => {
    if (isHaveUUID && !!nickName) {
      checkQuiz();
    } else {
      return (window.location.href = "/start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, nickName]);

  const answerQuiz = () => {
    window.location.href = `/read-qrcode/${id}`;
  };

  const resetEvent = () => {
    window.location.href = `/confirm-reset`;
  };

  return (
    <QuizStyled className="with-bg-img">
      <div className="group-head">
        <div className="count-quiz">
          {currentQuiz?.order}/{countQuiz}
        </div>
        <div className="group-head-right">
          <div className="btn-reset" onTouchEnd={resetEvent}>
            リセット
          </div>
          <Hint imgHint={`${getEnv("REACT_APP_IMG_URL")}/${dataQuiz?.hint}`} />
        </div>
      </div>

      <div className="quiz-order">クイズ {currentQuiz?.order}</div>
      <div className="group-img-quiz">
        <ImageCustom
          rootClassName="wrapper-img-start"
          className="img-quiz"
          src={`${getEnv("REACT_APP_IMG_URL")}/${dataQuiz?.image_url}`}
          alt="img"
        />
      </div>
      <div className="group-button">
        <ButtonCustom
          type="primary "
          width={358}
          onTouchEnd={answerQuiz}
          className="button-start"
        >
          回答
        </ButtonCustom>
      </div>
    </QuizStyled>
  );
};
