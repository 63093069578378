import NOTI_DOWNLOAD_IMAGE from "assets/images/note-download.png";
import { ModalCustom } from "components/ModalCustom";
import { ButtonCustom } from "components/button";
import { ImageCustom } from "components/image-custom";
import { getEnv } from "configs/env";
import useInfoQuiz from "hooks/infoQuiz";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "utils/constants";
import LocalStorage from "utils/storage";
import { ModalNoteDownloadStyled, SuccessAnswerStyled } from "./styled";

export const SuccessAnswerPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { correctUrl, currentQuiz } = useInfoQuiz();

  const [isOpenModalNoteDownload, setIsOpenModalNoteDownload] = useState(false);

  useEffect(() => {
    if (!LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      return (window.location.href = `/start`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)]);

  const openCaptureFrame = () => {
    if (LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      if (+currentQuiz?.order === 1) {
        setIsOpenModalNoteDownload(true);
      } else {
        return navigate(`/capture-frame/${id}`);
      }
    } else {
      return (window.location.href = `/start`);
    }
  };
  return (
    <>
      <SuccessAnswerStyled className="with-bg-img">
        <div></div>
        <ImageCustom
          alt="img-wrong"
          src={`${getEnv("REACT_APP_IMG_URL")}/${correctUrl}`}
          width={200}
          height={200}
        />
        <ButtonCustom onTouchEnd={openCaptureFrame}>
          フォトフレームで撮影
        </ButtonCustom>
      </SuccessAnswerStyled>
      <ModalNoteDownloadStyled id="success-page-id">
        {isOpenModalNoteDownload && (
          <ModalCustom
            open={isOpenModalNoteDownload}
            contentClassName="content-modal-note-download"
            onCancel={() => {
              setIsOpenModalNoteDownload(false);
            }}
            getContainer={document.getElementById("success-page-id")}
            footer={
              <div className="group-button-note-download">
                <ButtonCustom
                  key="back"
                  typeButton="cancel"
                  width={157}
                  rootClassName="btn-cancel"
                  onTouchEnd={() => {
                    setIsOpenModalNoteDownload(false);
                    return navigate(`/capture-frame/${id}`);
                  }}
                >
                  閉じる
                </ButtonCustom>
              </div>
            }
          >
            <div className="wrapper-note-download-img">
              <img
                className="note-download-img"
                alt="capture-img"
                src={NOTI_DOWNLOAD_IMAGE}
              />
            </div>
          </ModalCustom>
        )}
      </ModalNoteDownloadStyled>
    </>
  );
};
