import { ConnectInternetContext } from "App";
import { notification } from "antd";
import { QUIZ_API } from "api/quiz";
import CaptureIcon from "assets/images/capture.svg";
import SwitchCameraIcon from "assets/images/switch-camera.svg";
import { ModalCustom } from "components/ModalCustom";
import { ButtonCustom } from "components/button";
import { getEnv } from "configs/env";
import { saveAs } from "file-saver";
import useInfoQuiz from "hooks/infoQuiz";
import useDefaultPopup from "hooks/useDefaultPopup";
import useOrientationChange from "hooks/useOrientationChange";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import {
  FACING_MODE_ENVIRONMENT,
  FACING_MODE_USER,
  LOCAL_STORAGE_KEY,
  isHaveUUID,
  nickName,
} from "utils/constants";
import LocalStorage from "utils/storage";
import { CaptureFrameStyled } from "./styled";

const videoConstraints = {
  width: 1280,
  height: 720,
};

export const CaptureFramePage = () => {
  const { id } = useParams();
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const { handleShowWhenNotConnectInternet } = useContext(
    ConnectInternetContext
  );
  const { orientation } = useOrientationChange(false);

  const videoConstraintsCam = useMemo(() => {
    if (+orientation === 0) {
      return {
        height: 1280,
        width: 720,
        ratio: 16 / 9,
      };
    }
    return {
      width: 1280,
      height: 720,
      ratio: 9 / 16,
    };
  }, [orientation]);

  const webcamRef = useRef();
  const canvasFrameRef = useRef();
  const timeRef = useRef();
  const timeCancelRef = useRef();
  const [resetCameraView, setResetCameraView] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [isClickDownload, setIsClickDownload] = useState(false);

  const [imgScreenShot, setImgScreenShot] = useState(null);
  const { userId, currentQuiz, setNextCurrentQuiz, getIdentityEvent } =
    useInfoQuiz();
  useDefaultPopup(!!imgScreenShot);

  const [imagePhotoFrame, setImagePhotoFrame] = useState("");

  const handleSwitchCamera = useCallback(() => {
    if (!resetCameraView) {
      setResetCameraView(true);
      setFacingMode((prevState) =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
      );
      timeRef.current = setTimeout(() => {
        setResetCameraView(false);
      }, 400);
    }
  }, [resetCameraView]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const getQuiz = async (idQuiz) => {
    if (idQuiz) {
      try {
        const dataQuiz = await QUIZ_API.getQuizById(idQuiz, userId);
        if (dataQuiz?.success) {
          LocalStorage.set(
            LOCAL_STORAGE_KEY.PHOTO_FRAME,
            dataQuiz?.data?.photo_frame
          );
          setImagePhotoFrame(dataQuiz?.data?.photo_frame);
        } else {
          notification.error({
            message: dataQuiz?.message,
          });
        }
      } catch (error) {
        if (handleShowWhenNotConnectInternet) {
          handleShowWhenNotConnectInternet();
        }
      }
    }
  };

  const checkQuiz = async () => {
    await getIdentityEvent(null, nickName, id, false, async () => {
      await getQuiz(id);
    });
  };

  useEffect(() => {
    if (isHaveUUID && !!nickName) {
      checkQuiz();
    } else {
      return (window.location.href = "/start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, nickName]);

  useEffect(() => {
    return () => {
      clearTimeout(timeRef.current);
      clearTimeout(timeCancelRef.current);
    };
  }, []);

  const handleNextQuiz = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isClickDownload) {
      try {
        const option = {
          user_id: userId,
          quiz_id: id,
        };
        const dataPhoto = await QUIZ_API.takePhotoFrame(option);
        if (dataPhoto?.success) {
          setNextCurrentQuiz(
            +currentQuiz?.order + 1,
            true,
            dataPhoto?.data?.last_quiz
          );
        } else {
          notification.error({
            message: dataPhoto?.message,
          });
        }
      } catch (error) {
        if (handleShowWhenNotConnectInternet) {
          handleShowWhenNotConnectInternet();
        }
      }
    }
  };

  const capture = useCallback(() => {
    const canvas = canvasFrameRef.current;
    const context = canvas?.getContext("2d");
    const video = webcamRef?.current;
    const imageSrcShot = webcamRef.current.getScreenshot();
    if (!context || !video) {
      return;
    }
    const elementShot = document.createElement("img");
    elementShot.src = imageSrcShot;
    elementShot.onload = () => {
      context.drawImage(
        elementShot,
        0,
        0,
        videoConstraints?.height,
        videoConstraints?.width
      );
      const img = document.getElementById("frame");
      context.drawImage(
        img,
        0,
        0,
        videoConstraints?.height,
        videoConstraints?.width
      );
      const imageSrc = canvas.toDataURL();
      setImgScreenShot(imageSrc);
    };
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setResetCameraView(true);
    setIsClickDownload(false);
    setImgScreenShot(null);
    timeCancelRef.current = setTimeout(() => {
      setResetCameraView(false);
    }, 400);
  };

  const convertBase64ToFile = (base64String, fileName) => {
    let arr = base64String.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  };

  const downloadCanvas = useCallback(() => {
    setIsClickDownload(true);
    const fileName = `photo_frame_${id}_${moment().format("YYYYMMDD")}.png`;
    let file = convertBase64ToFile(imgScreenShot, fileName);
    saveAs(file, fileName);
  }, [id, imgScreenShot]);

  return (
    <CaptureFrameStyled id="capture-frame-page">
      <div className="group-cam">
        {!resetCameraView && imageLoaded && (
          <Webcam
            disablePictureInPicture={true}
            audio={false}
            ref={webcamRef}
            mirrored={facingMode === FACING_MODE_USER}
            screenshotFormat="image/png"
            videoConstraints={{
              height: videoConstraintsCam?.width,
              width: videoConstraintsCam?.height,
              facingMode,
              aspectRatio: videoConstraintsCam?.ratio,
            }}
          />
        )}

        <canvas
          style={{
            display: "none",
          }}
          id="canvas-frame"
          className="canvas-frame"
          width={videoConstraints.height}
          height={videoConstraints.width}
          ref={canvasFrameRef}
        />
        <img
          alt="frame"
          id="frame"
          className="photo-frame"
          crossOrigin="conanonymous"
          src={`${getEnv("REACT_APP_IMG_URL")}/${imagePhotoFrame}`}
          onLoad={handleImageLoad}
        />
      </div>
      {imageLoaded && (
        <div className="group-button">
          <ButtonCustom
            width={60}
            height={60}
            typeButton="cancel"
            rootClassName="btn-switch"
            bordered
            onTouchEnd={handleSwitchCamera}
          >
            <img
              onDragStart={(e) => {
                e.preventDefault();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              alt="switch"
              src={SwitchCameraIcon}
            />
          </ButtonCustom>
          <ButtonCustom
            rootClassName="btn-capture"
            width={80}
            height={80}
            bordered
            onTouchEnd={capture}
          >
            <img
              onDragStart={(e) => {
                e.preventDefault();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              alt="switch"
              src={CaptureIcon}
            />
          </ButtonCustom>
          <ButtonCustom
            style={{
              opacity: 0,
              pointerEvents: "none",
            }}
            width={60}
            height={60}
            bordered
          >
            <img
              onDragStart={(e) => {
                e.preventDefault();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              alt="switch"
              src={CaptureIcon}
            />
          </ButtonCustom>
        </div>
      )}

      {imgScreenShot && (
        <ModalCustom
          open={!!imgScreenShot}
          onCancel={handleCancel}
          getContainer={document.getElementById("capture-frame-page")}
          footer={
            <div className="group-button-frame">
              <ButtonCustom
                key="back"
                typeButton="cancel"
                width={157}
                rootClassName="btn-cancel"
                onTouchEnd={handleCancel}
              >
                撮影し直す
              </ButtonCustom>
              <ButtonCustom
                rootClassName={`btn-next ${!isClickDownload && "disable"}`}
                width={157}
                key="submit"
                type="primary"
                onTouchEnd={handleNextQuiz}
                disabled={!isClickDownload}
              >
                次のクイズへ
              </ButtonCustom>
            </div>
          }
        >
          <div className="wrap-frame">
            <div className="btn-download-text" onTouchEnd={downloadCanvas}>
              ダウンロード
            </div>

            <div className="capture-img-frame">
              <img
                className="capture-img"
                alt="capture-img"
                src={imgScreenShot}
              />
            </div>
          </div>
        </ModalCustom>
      )}
    </CaptureFrameStyled>
  );
};
