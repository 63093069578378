import { AxiosClient } from "configs/axios/axios";
import { getEnv } from "configs/env";

export const QUIZ_API = {
  getQuizById: async (id, userId) => {
    try {
      const response = await new AxiosClient().get(
        `${getEnv("REACT_APP_API_SERVER")}/api/quiz/${id}?user_id=${userId}`
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  checkAnswer: async (body) => {
    try {
      const response = await new AxiosClient().put(
        `${getEnv("REACT_APP_API_SERVER")}/api/user/answering`,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  takePhotoFrame: async (body) => {
    try {
      const response = await new AxiosClient().put(
        `${getEnv("REACT_APP_API_SERVER")}/api/user/photo-frame`,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  },
};
