import { Button } from "antd";
import styled, { css } from "styled-components";

export const ButtonStyled = styled(Button)`
  width: ${({ width }) => width}px;
  max-width: 100%;
  height: ${({ height }) => height}px;
  user-select: none;
  &:active,
  :focus,
  :hover,
  :target,
  :visited,
  :focus-visible {
    color: #fff !important;
    outline: none !important;
  }
  &.submit,
  &.cancel {
    ${({ width }) =>
      width
        ? css`
            width: ${width}px;
          `
        : ""};
    border-radius: 20px;
    background: #1888e0;
    position: relative;
    z-index: 1;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.18px;
    border: none;
    color: #fff !important;
    span {
      padding-top: 4px;
    }
  }
  &.submit {
    background: #03be0a !important;
    box-shadow: 0px 3px 0px 0px #118007;
    &:active {
      background: #03be0a !important;
    }
    &:disabled {
      background: #acacac !important;
      box-shadow: 0px 3px 0px 0px #5e5e5e;
    }
  }
  &.cancel {
    background: linear-gradient(158deg, #0d8afc -28.58%, #9747ff 81.77%) !important;
    box-shadow: 0px 3px 0px 0px #773cc9;
    &:active {
      background: linear-gradient(
        158deg,
        #0d8afc -28.58%,
        #9747ff 81.77%
      ) !important;
    }
    &:disabled {
      background: #acacac !important;
      box-shadow: 0px 3px 0px 0px #5e5e5e;
    }
  }
  .blur-icon {
    position: absolute;
    top: -3px;
    left: 5px;
  }
  &.bordered {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &:active,
    :focus,
    :hover,
    :target,
    :visited,
    :focus-visible {
      color: #fff !important;
      outline: none !important;
      border: none !important;
      border-color: white !important;
    }
    &-submit {
      background: #03be0a !important;
      &:active {
        background: #03be0a !important;
      }
    }
    &-cancel {
      background: linear-gradient(
        158deg,
        #0d8afc -28.58%,
        #9747ff 81.77%
      ) !important;
      &:active {
        background: linear-gradient(
          158deg,
          #0d8afc -28.58%,
          #9747ff 81.77%
        ) !important;
      }
    }
  }
`;
