import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

export const SuccessAnswerStyled = styled.div`
  width: 100%;
  height: 100%;
  min-height: var(--doc-height);
  box-sizing: border-box;
  padding: 20px 20px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.MD)} {
    .wrapper-note-download-img {
      height: calc(var(--doc-height) * 80 / 100);
      width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
      max-height: unset;
    }
    .group-button-note-download {
      .btn-cancel {
        width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
      }
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      .wrapper-note-download-img {
        height: calc(var(--doc-height) * 63 / 100);
        width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
        max-height: unset;
      }
      .group-button-note-download {
        .btn-cancel {
          width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
        }
      }
    }
  }
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .wrapper-note-download-img {
      height: calc(var(--doc-height) * 73 / 100);
      width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
      max-height: unset;
    }
    .group-button-note-download {
      .btn-cancel {
        width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
      }
    }
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      .wrapper-note-download-img {
        height: calc(var(--doc-height) * 63 / 100);
        width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
        max-height: unset;
      }
      .group-button-note-download {
        .btn-cancel {
          width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
        }
      }
    }
  }
`;

export const ModalNoteDownloadStyled = styled.div`
  .popup-wrapper {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper-note-download-img {
    position: relative;
    border-radius: 10px;
    border: 2px solid #afb1b6;
    max-width: 100%;
    width: 306px;
    height: calc(var(--doc-height) * 70 / 100);
    max-height: 464px;
    @media screen and (max-width: 360px) {
      width: 280px !important ;
    }
    @media screen and (max-width: 320px) {
      width: 260px !important ;
      max-height: 314px;
    }
    @media screen and (max-width: 280px) {
      width: 200px;
      max-height: calc(200px * 16 / 9);
    }
    .note-download-img {
      width: 100%;
      height: 100%;
    }
  }
  .group-button-note-download {
    margin-top: 20px;
    .btn-cancel {
      width: 306px;
      @media screen and (max-width: 360px) {
        width: 280px;
      }
      @media screen and (max-width: 320px) {
        width: 260px;
      }
      @media screen and (max-width: 280px) {
        width: 200px;
      }
    }
  }
  ${ScreenReponsive}
`;
