import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
const useBackReload = () => {
  const location = useLocation();
  const handleEvent = useCallback(() => {
    const pathQR = location?.pathname?.split("/")?.[1] || "";
    if (pathQR === "read-qrcode") {
      window?.location?.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  }, [handleEvent]);
};

export default useBackReload;
