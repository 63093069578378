import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .group-cam {
      position: relative;
      width: auto;
      border-radius: 30px;
      border: 2px solid #afb1b6;
      height: calc(var(--doc-height) - 100px - 16px - 32px);
      max-width: unset;
      max-height: unset;
      width: calc(calc(var(--doc-height) - 100px - 16px - 32px) * 9 / 16);
    }
    .wrap-frame {
      position: relative;
      border-radius: 17px;
      width: auto;
      max-width: unset;
      border: 2px solid #afb1b6;
      height: calc(var(--doc-height) * 78 / 100);
      width: calc(calc(var(--doc-height) * 78 / 100) * 9 / 16);
      .capture-img-frame {
        width: 100%;
        height: 100%;
        .capture-img {
          border-radius: 17px;
          width: 100%;
          height: 100%;
        }
      }
    }
    .group-button-frame {
      width: calc(calc(var(--doc-height) * 78 / 100) * 9 / 16);
      .btn-cancel,
      .btn-next {
        width: 209px;
      }
    }
    .group-button {
      width: calc(calc(var(--doc-height) - 100px - 16px - 32px) * 9 / 16);
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .btn-capture {
        width: 100px;
        height: 100px;
      }
      .btn-switch {
        width: 80px;
        height: 80px;
      }
    }
  }
  ${ResponsiveUI.greaterThan(SIZE.MD)} {
    .group-cam {
      position: relative;
      width: auto;
      border-radius: 30px;
      border: 2px solid #afb1b6;
      height: calc(var(--doc-height) - 100px - 16px - 32px);
      max-width: unset;
      width: calc(calc(var(--doc-height) - 100px - 16px - 32px) * 9 / 16);
    }
    .wrap-frame {
      position: relative;
      border-radius: 17px;
      width: auto;
      max-width: unset;
      border: 2px solid #afb1b6;
      height: calc(var(--doc-height) * 80 / 100);
      width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
    }
    .group-button-frame {
      width: calc(calc(var(--doc-height) * 80 / 100) * 9 / 16);
      .btn-cancel {
        width: 257px;
        height: 90px;
        margin-right: 24px;
      }
      .btn-next {
        width: 257px;
        height: 90px;
      }
    }
    .group-button {
      width: calc(calc(var(--doc-height) - 100px - 16px - 32px) * 9 / 16);
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .btn-capture {
        width: 120px;
        height: 120px;
      }
      .btn-switch {
        width: 100px;
        height: 100px;
      }
      ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
        .btn-capture {
          width: 80px;
          height: 80px;
        }
        .btn-switch {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.PAD1)} {
    .wrap-frame {
      position: relative;
      border-radius: 17px;
      border: 2px solid #afb1b6;
      margin: auto;
      height: calc(var(--doc-height) * 73 / 100);
      width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
    }
    .group-button-frame {
      width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
      .btn-cancel,
      .btn-next {
        width: 157px;
        height: 55px;
        font-size: 14px;
      }
    }
  }
  ${ResponsiveUI.lessThanH(SIZEH.MAC13)} {
    .wrap-frame {
      position: relative;
      border-radius: 17px;
      border: 2px solid #afb1b6;
      height: calc(var(--doc-height) * 68 / 100);
      width: calc(calc(var(--doc-height) * 68 / 100) * 9 / 16);
      margin: auto;
    }
    .group-button-frame {
      width: calc(calc(var(--doc-height) * 68 / 100) * 9 / 16);
      .btn-cancel,
      .btn-next {
        width: 157px;
        height: 55px;
        margin-right: 8px;
        font-size: 12px;
      }
      .btn-capture {
        width: 80px;
        height: 80px;
      }
      .btn-switch {
        width: 60px;
        height: 60px;
      }
    }
  }
  @media screen and (max-height: 500px) {
    .wrap-frame {
      position: relative;
      height: calc(var(--doc-height) * 63 / 100);
      width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
      margin: auto;
    }
    .group-button-frame {
      width: calc(calc(var(--doc-height) * 63 / 100) * 9 / 16);
    }
  }
`;

export const CaptureFrameStyled = styled.div`
  width: 100%;
  height: var(--doc-height);
  box-sizing: border-box;
  background-color: #1a1a1a;
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .popup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    .popup-content {
      margin-top: 40px;
      width: 100%;
    }
  }

  .group-cam {
    position: relative;
    max-width: 100%;
    border-radius: 30px;
    border: 2px solid #afb1b6;
    height: calc(var(--doc-height) - 80px - 16px - 32px);
    width: calc(calc(var(--doc-height) - 80px - 16px - 32px) * 9 / 16);
    video {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
        opacity: 0;
      }
      &::-webkit-media-controls {
        display: none !important;
        opacity: 0;
        -webkit-appearance: none;
      }
    }
    .canvas-frame {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .photo-frame {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }
  .group-button {
    width: calc(calc(var(--doc-height) - 80px - 16px - 32px) * 9 / 16);
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .group-button-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
    @media screen and (max-height: 800px) {
      margin-top: 12px;
    }
    .btn-cancel {
      user-select: none;
      width: 157px;
      margin-right: 8px;
    }
    .btn-next {
      user-select: none;
      width: 157px;
      white-space: pre-wrap;
      padding: 4px 12px 8px;
    }
  }
  .wrap-frame {
    position: relative;
    border-radius: 17px;
    border: 2px solid #afb1b6;
    height: calc(var(--doc-height) * 73 / 100);
    width: calc(calc(var(--doc-height) * 73 / 100) * 9 / 16);
    margin: auto;
    .capture-img-frame {
      width: 100%;
      height: 100%;
      .capture-img {
        border-radius: 17px;
        width: 100%;
        height: 100%;
      }
    }

    .btn-download {
      position: absolute;
      user-select: none;
      z-index: 2;
      top: -26px;
      left: 0;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
    }
    .btn-download-text {
      position: absolute;
      user-select: none;
      z-index: 2;
      top: -34px;
      left: 0;
      transform: translateY(-50%);
      white-space: nowrap;
      border-radius: 7px;
      background: linear-gradient(180deg, #f1f4f9 22.73%, #c6d1e3 105.84%);
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
      color: #118007;
      font-weight: 900;
      font-size: 16px;
      padding: 10px 12px 9px 13px;
      width: 96px;
    }
  }
  ${ScreenReponsive}
`;
