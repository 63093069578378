import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ModalCustom } from "components/ModalCustom";
import { ButtonCustom } from "components/button";
import useInfoQuiz from "hooks/infoQuiz";
import { useRef, useState } from "react";
import { REG_NICK_NAME } from "utils/regex";
import { ConfirmNameStyled } from "./styled";
import LocalStorage from "utils/storage";
import { LOCAL_STORAGE_KEY } from "utils/constants";

export const ConfirmNamePage = () => {
  const [messageShow, setMessageShow] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const refInput = useRef();
  const [valueSearch, setValueSearch] = useState("");

  const { getIdentityEvent, startEventCurrentQuiz } = useInfoQuiz();

  const handleShowError = (message = "") => {
    setMessageShow(message);
    setIsShowError(true);
  };

  const handleNextWithName = async () => {
    const fpPromise = FingerprintJS.load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      LocalStorage.set(LOCAL_STORAGE_KEY.UUID_DEVICE, result.visitorId);
      refInput?.current?.blur();
      await getIdentityEvent(
        result.visitorId,
        valueSearch,
        null,
        true,
        async (userId) => {
          await startEventCurrentQuiz(userId);
        },
        handleShowError
      );
    })();
  };

  const handleClick = () => {
    if (refInput.current) {
      refInput.current.focus();
      refInput.current.setSelectionRange(
        refInput.current.value.length,
        refInput.current.value.length
      );
    }
  };

  return (
    <>
      <ModalCustom
        open={isShowError}
        wrapperClassName="popup-connect-error"
        footer={
          <div
            className="btn-close-popup"
            onClick={() => {
              setIsShowError(false);
            }}
          >
            OK
          </div>
        }
      >
        <div className="text-error-popup">{messageShow}</div>
      </ModalCustom>
      <ConfirmNameStyled className="with-bg-img">
        <div className="wrapper">
          <div className="wrap-info">
            <div className="header">ニックネーム登録</div>
            <div className="item mgt">
              あなたの端末のニックネームを入力してください。
            </div>
            <div className="item">
              （ひらがな、カタカナ、漢字、半角アルファベット大文字小文字・数字のみ）
            </div>
            <input
              ref={refInput}
              className="input-name"
              value={valueSearch}
              maxlength="255"
              onClick={handleClick}
              onChange={(e) => {
                const value = e?.target?.value;
                const newValue = value.match(REG_NICK_NAME);
                if (value) {
                  newValue && setValueSearch(newValue[0]);
                } else {
                  setValueSearch("");
                }
              }}
            />
            <div className="item mgt">※半角4文字以上～20文字以下、</div>
            <div className="item">　全角2文字以上～10文字以下 </div>
            <div className="item">※空白文字不可</div>
            <div className="item">
              <div>※</div>
              <div className="item-child">
                同じニックネームがある場合、アラートが表示されます。
              </div>
            </div>
            <div className="item">
              <div className="item-empty"></div>
              <div className="item-child">
                その場合は別のニックネームを入力してください。
              </div>
            </div>
          </div>
          <ButtonCustom
            rootClassName="button-start-name"
            onTouchEnd={() => {
              if (valueSearch?.trim()) {
                handleNextWithName();
              }
            }}
            disabled={!valueSearch?.trim()}
          >
            ニックネームを登録
          </ButtonCustom>
        </div>
      </ConfirmNameStyled>
    </>
  );
};
