export const SIZE = {
  XXL4: 2561, // DESKTOP L3

  XXL3: 2161, // DESKTOP L2

  XXL2: 1921, // DESKTOP L1

  XXL: 1601, // DESKTOP L

  XL2: 1441, // DESKTOP M+

  XL1: 1367, // DESKTOP M

  XL: 1281, // DESKTOP M

  LG: 1025, // TABLET L

  MD: 769, // TABLET M

  SM: 573, // Mobile L

  XS: 423, // Mobile M

  XXS: 390,

  XXXS: 321,
};

export const SIZEH = {
  PAD3: 1386,

  PAD2: 1025,

  PAD1: 860,

  MAC17: 773,

  MAC16: 760,

  MAC14: 670,

  MAC13: 606,

  MAC12: 500,
};

export default class ResponsiveUI {
  height;

  width;

  static SIZE = SIZE;

  static SIZEH = SIZEH;

  constructor(size) {
    this.height = size.height;

    this.width = size.width;
  }

  static greaterThan(windowSize) {
    return `@media only screen and (min-width: ${windowSize}px)`;
  }

  static lessThan(windowSize) {
    return `@media only screen and (max-width: ${windowSize - 1}px)`;
  }

  static between(min, max) {
    return `@media only screen and (min-width: ${min}px) and (max-width: ${
      max - 1
    }px)`;
  }

  static greaterThanH(windowSize) {
    return `@media only screen and (min-height: ${windowSize}px)`;
  }

  static lessThanH(windowSize) {
    return `@media only screen and (max-height: ${windowSize - 1}px)`;
  }

  static betweenH(min, max) {
    return `@media only screen and (min-height: ${min}px) and (max-height: ${
      max - 1
    }px)`;
  }

  test() {
    return "";
  }
}
