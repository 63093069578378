import { ConnectInternetContext } from "App";
import { notification } from "antd";
import { QUIZ_API } from "api/quiz";
import ViewBotLeft from "assets/images/view-bot-left.svg";
import ViewBotRight from "assets/images/view-bot-right.svg";
import ViewTopLeft from "assets/images/view-top-left.svg";
import ViewTopRight from "assets/images/view-top-right.svg";
import useInfoQuiz from "hooks/infoQuiz";
import moment from "moment";
import { useContext, useEffect } from "react";
import { isSafari } from "react-device-detect";
import QrReader from "react-qr-reader";
import { useNavigate, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { LOCAL_STORAGE_KEY } from "utils/constants";
import LocalStorage from "utils/storage";
import { ReadQRCodeStyled } from "./styled";

export const ReadQRCodePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentQuiz, userId } = useInfoQuiz();
  const { handleShowWhenNotConnectInternet } = useContext(
    ConnectInternetContext
  );

  useEffect(() => {
    if (!LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)) {
      return (window.location.href = `/start`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalStorage.get(LOCAL_STORAGE_KEY.NICK_NAME)]);

  const handleScan = async (data) => {
    if (data && userId && currentQuiz?.quiz_id) {
      try {
        const option = {
          user_id: userId,
          quiz_id: currentQuiz?.quiz_id,
          time: moment().utc().valueOf(),
          url: data,
        };
        const dataAnswer = await QUIZ_API.checkAnswer(option);
        if (dataAnswer?.success) {
          if (dataAnswer?.data?.is_success) {
            return navigate(`/success-answer/${id}`);
          } else {
            return navigate("/wrong-answer");
          }
        } else {
          notification.error({
            message: dataAnswer?.message,
          });
        }
      } catch (error) {
        if (handleShowWhenNotConnectInternet) {
          handleShowWhenNotConnectInternet();
        }
      }
    }
  };

  const ViewFinderQR = () => {
    return (
      <>
        <div className="view-finder-qr">
          <img
            alt="view-finder-qr-top-left"
            className="view-finder-qr-top-left"
            src={ViewTopLeft}
          />
          <img
            alt="view-finder-qr-top-right"
            className="view-finder-qr-top-right"
            src={ViewTopRight}
          />
          <img
            alt="view-finder-qr-bot-left"
            className="view-finder-qr-bot-left"
            src={ViewBotLeft}
          />
          <img
            alt="view-finder-qr-bot-right"
            className="view-finder-qr-bot-right"
            src={ViewBotRight}
          />
        </div>
      </>
    );
  };

  const handleError = (err) => {
    // alert(err);
  };

  return (
    <ReadQRCodeStyled>
      <div className="title">
        クイズに回答するQRコードを フレームに合わせてください。
      </div>
      <div className="wrap-reader">
        {!isSafari && (
          <Webcam
            audio={false}
            style={{
              display: "none",
            }}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 600,
              height: 375,
              facingMode: "environment",
            }}
          />
        )}

        <QrReader
          delay={300}
          className="qr-reader"
          onScan={handleScan}
          onError={handleError}
          showViewFinder={false}
          facingMode="environment"
          constraints={{
            // width: 412,
            // height: 412,
            facingMode: "environment",
          }}
        />
        <ViewFinderQR />
      </div>
    </ReadQRCodeStyled>
  );
};
