import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { translationEN } from 'translate/en';
import { translationJP } from 'translate/jp';
import Storage from 'utils/storage';
import { LOCAL_STORAGE_KEY } from 'utils/constants';

// the translations
const resources = {
  en: { translation: translationEN },
  jp: { translation: translationJP },
};

const INIT_LANGUAGE = Storage.get(LOCAL_STORAGE_KEY?.LANGUAGE) || 'en';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: INIT_LANGUAGE,
    fallbackLng: INIT_LANGUAGE,
    preload: [INIT_LANGUAGE],
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    react: { useSuspense: false },
    interpolation: { escapeValue: false },
  });
i18n.off('languageChanged');
i18n.on('languageChanged', (language) => {
  Storage.set(LOCAL_STORAGE_KEY.LANGUAGE, language);
});

export default i18n;
