import { ButtonCustom } from "components/button";
import { ImageCustom } from "components/image-custom";
import { getEnv } from "configs/env";
import useInfoQuiz from "hooks/infoQuiz";
import { useNavigate } from "react-router-dom";
import { GoalStyled } from "./styled";

export const Goal = () => {
  const navigate = useNavigate();

  const { thumbnailGoal } = useInfoQuiz();
  const resetEvent = () => {
    navigate("/confirm-reset");
  };

  return (
    <GoalStyled className="with-bg-img">
      {thumbnailGoal && (
        <ImageCustom
          rootClassName="wrapper-img-goal"
          className="img-goal"
          src={`${getEnv("REACT_APP_IMG_URL")}/${thumbnailGoal}`}
          alt="img"
        />
      )}

      <ButtonCustom
        rootClassName="button-reset"
        typeButton="cancel"
        onTouchEnd={resetEvent}
      >
        もう一度最初から
      </ButtonCustom>
    </GoalStyled>
  );
};
