import { useEffect, useState } from "react";

const useCheckConnectInternet = () => {
  const [isShowErrorInternet, setIsShowErrorInternet] = useState(true);
  const [isOnline, setIsOnline] = useState(window?.navigator?.onLine || true);

  const handleShowWhenNotConnectInternet = () => {
    if (!isOnline) {
      setIsShowErrorInternet(true);
    }
  };

  useEffect(() => {
    window.addEventListener("offline", (e) => {
      setIsOnline(false);
      setIsShowErrorInternet(true);
    });

    window.addEventListener("online", (e) => {
      setIsOnline(true);
      setIsShowErrorInternet(false);
    });
  }, []);

  return {
    isShowErrorInternet,
    isOnline,
    setIsShowErrorInternet,
    handleShowWhenNotConnectInternet,
  };
};

export default useCheckConnectInternet;
