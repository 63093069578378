import { AxiosClient } from "configs/axios/axios";
import { getEnv } from "configs/env";

const EVENT_API = {
  getEvent: async (params) => {
    try {
      const response = await new AxiosClient().get(
        `${getEnv("REACT_APP_API_SERVER")}/api/event`,
        { params }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  getHistoryEvent: async (body) => {
    try {
      const response = await new AxiosClient().post(
        `${getEnv("REACT_APP_API_SERVER")}/api/user/identify`,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  startEvent: async (body) => {
    try {
      const response = await new AxiosClient().post(
        `${getEnv("REACT_APP_API_SERVER")}/api/user/event-start`,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  resetEventApi: async (body) => {
    try {
      const response = await new AxiosClient().post(
        `${getEnv("REACT_APP_API_SERVER")}/api/user/reset-event`,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  },
};

export default EVENT_API;
