import styled from "styled-components";

export const WrongAnswerStyled = styled.div`
  width: 100%;
  height: 100%;
  min-height: var(--doc-height);
  box-sizing: border-box;

  padding: 20px 20px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
