import ResponsiveUI, { SIZE, SIZEH } from "assets/styles/responsive";
import styled, { css } from "styled-components";

const ScreenReponsive = css`
  ${ResponsiveUI.greaterThan(SIZE.SM)} {
    .wrapper-img-goal {
      padding-bottom: 30px;
      height: calc(var(--doc-height) - 70px - 32px - 40px);
      width: calc(calc(var(--doc-height) - 70px - 32px - 40px) * 9 / 16);
      max-height: unset;
      max-width: 100%;
      .img-goal {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }
    .button-reset {
      width: calc(calc(var(--doc-height) - 70px - 32px - 40px) * 9 / 16);
      max-width: 100%;
      height: 80px;
      font-size: 24px;
    }
  }
`;

export const GoalStyled = styled.div`
  width: 100%;
  height: var(--doc-height, 100vh);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 8px 16px;
  .wrapper-img-goal {
    padding-bottom: 20px;
    width: 358px;
    aspect-ratio: 9 / 16;
    max-height: 635px;
    height: calc(var(--doc-height) - 70px - 32px - 20px);
    @media screen and (max-width: 360px) {
      width: 330px;
    }
    @media screen and (max-width: 320px) {
      width: 100%;
    }
    .img-goal {
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
  }
  .button-reset {
    width: calc(calc(var(--doc-height) - 70px - 32px - 20px) * 9 / 16);
    max-width: 358px;
    height: 70px;
    font-size: 24px;
    ${ResponsiveUI.lessThanH(SIZEH.MAC14)} {
      width: 358px;
      @media screen and (max-width: 360px) {
        width: 330px;
      }
    }
    @media screen and (max-width: 360px) {
      width: 330px;
    }
    @media screen and (max-width: 320px) {
      width: 100%;
    }
  }
  ${ScreenReponsive}
`;
